import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/Layout.jsx";
import { PageHeader } from "../components/PageHeader";
import ProQuoLogo from "../assets/proquo.svg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader title="Northwestern Mutual" startDate="2019" endDate="2021" positions={["Senior Full Stack Engineer"]} imgSrc="/nm.png" squareImg mdxType="PageHeader" />
    <p>{`At Northwestern Mutual, I was lucky to be a part of the `}<a parentName="p" {...{
        "href": "https://innovation.northwesternmutual.com"
      }}>{`innovation team`}</a>{`. We took and created ideas, innovate and executed on them, and moved onto the next one.`}</p>
    <p>{`Sometimes, that meant exploring a venture in an adjacent market to NM's core business and running it like a pure startup. Other times, it meant delivering on internal software that provided new ways for NM financial reps to work more efficiently`}</p>
    <p>{`No matter the project, they always involved starting from square one, taking the idea from the research/discovery/development phase all the way until a production rollout.`}</p>
    <h2>{`Projects`}</h2>
    <img alt="Proquo" src={ProQuoLogo} width="180" style={{
      "marginTop": "2rem",
      "marginBottom": "2rem"
    }} />
    <p><a parentName="p" {...{
        "href": "https://www.getproquo.com"
      }}>{`Proquo`}</a>{` started off as a platform that gave athletes rewards for performing well in public events. These "bounties" gave the users discounts from retailers like Nike and Garmin, in exchange for the user's consented data.`}</p>
    <p>{`As the pandemic hit and events and races started to shut down, Proquo made a pivot to become a `}<strong parentName="p">{`racing platform`}</strong>{`, specializing in `}<a parentName="p" {...{
        "href": "https://www.getproquo.com/virtual-challenge-management"
      }}>{`virtual events`}</a>{`. We'd noticed how a lot of the race managers we were working with had a need here, and it provided a new route to move forward and stay relevant with as COVID remained detrimental.`}</p>
    <h3>{`Plan Execution`}</h3>
    <p>{`Plan Execution was a project to develop, research, and test an app that `}<strong parentName="p">{`automates clients' financial goals`}</strong>{`.`}</p>
    <p>{`A financial rep could help a user come up with concrete, prioritized goals for a client, then hand it off to our system to do the money movement automatically for them each month.`}</p>
    <p>{`After 6 months of research and development, we successfully got the company to notice the product's viability; the project was handed off to another team for continued development.`}</p>
    <h3>{`MIB️`}</h3>
    <p>{`MIB is a vendor that works with healthcare organization to provide an `}<strong parentName="p">{`electronic health record API`}</strong>{`. We wanted to figure out if it could be a viable solution for more `}<strong parentName="p">{`efficient underwriting`}</strong>{`.`}</p>
    <p>{`As the sole engineer on this project, it was my job to build some scripts that could ingest >17,000 NM applicants, search for EHRs using MIB's API, and report back the hit rate and speed of results. The results of this research/POC was the eventual green-light and handoff for a different team to build out a fully featured app. It proved to be even more useful in overcoming the challengs of underwriting in the COVID era.`}</p>
    <h3>{`Exam Connect`}</h3>
    <p>{`Exam Connect is a webapp that `}<strong parentName="p">{`helps financial representatives schedule paramedical exams`}</strong>{` for insurance applicants.`}</p>
    <p>{`Exam Connect was piloted successfully, and handed off to another team for maintenance. Financial reps now use this daily, providing a much smoother process than the old days of calling back and forth between applicant and exam vendor.`}</p>
    <h2>{`Other responsibilities`}</h2>
    <ul>
      <li parentName="ul">{`Maintenance of NM's `}<a parentName="li" {...{
          "href": "https://innovation.northwesternmutual.com"
        }}>{`innovation website`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      