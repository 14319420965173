import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/Layout.jsx";
import { PageHeader } from "../components/PageHeader";
import ScratchImage from "../assets/scratch-club.png";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader title="University of Wisconsin-Madison" secondary="Bachelor of Arts in Computer Science" startDate="2014" endDate="2017" imgSrc="/uw-madison-red.jpg" mdxType="PageHeader" />
    <p>{`When I was in high school and deciding where to go to school, my strategy was pretty simple -- find a school good in Computer Sciences and go there. Madison is a top-10 school in CS and was about an hour from home in Milwaukee, so it was pretty much a no brainer. The vaunted "experience" of being a badger didn't really play into my decision to go there, but it was just about the best "cherry on top" you could ask for.`}</p>
    <h3>{`Came for CS...`}</h3>
    <p>{`My CS education there lived up to the hype. I completely owe my strong understanding of computing fundamentals to the incredible peers and educators that I was privileged to learn with. I wouldn't be nearly as well-rounded as an engineer today without learning computing from the ground up at UW-Madison (which I have to remind myself sometimes when I'm tempted to think I would have been better off going to a boot camp instead).`}</p>
    <p>{`Plus, I had the awesome opportunity to lead a `}<a parentName="p" {...{
        "href": "https://www.cs.wisc.edu/lead-a-scratch-club-uw-madison-students/"
      }}>{`Scratch club`}</a>{`, where I taught kindegarteners how to code 😀.`}</p>
    <img alt="Teaching Scratch to lil' kiddos" src={ScratchImage} width="500" style={{
      "margin": "2rem auto"
    }} />
    <h3>{`...stayed for the rest`}</h3>
    <p>{`Early on, the general education classes required of a CS degree were a formality to me, and a wasteful one at that. Yet, as the semesters passed, I ended up valuing and enjoying these classes even more than my CS ones.`}</p>
    <p>{`Classes in the liberal arts, literature, writing, science fiction, and so on, ended up being crucial in the way I think today. Being in such a "left-brained" industry such as software can potentially be pretty limiting if you don't expand your horizons past 1's and 0's. I'm lucky UW-Madison pushed me to take this extra classes and gain perspective I probably never would have seeked out otherwise`}</p>
    <h3>{`Overall...`}</h3>
    <p>{`I'm extremely happy with my decision to go to Madison, and loved the city itself. So much so that I stayed for two years after graduation and even decided to get married there even after moving back to Milwaukee. It holds an extremely fond place in my heart.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      