import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/Layout.jsx";
import { PageHeader } from "../components/PageHeader";
import DoofiImg from "../assets/thedoofi.com.png";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader title="✨ Portfolio" mdxType="PageHeader">
  <p>
    <em>
      Building side projects just for the sake of building side projects (or,
      just for validation of my skills and craftsmanship) has always kind of
      bored me. My mind is far too outcome-oriented to work on a side project
      that is, or likely will be, worthless to the world -- especially when I
      can spend that time solving real problems for myself, my company, or even
      my family.
    </em>
  </p>
  <p>
    <em>
      So, while there are some stereotypical "side projects" included here, what
      I really care to showcase is <strong>tangible</strong> and{" "}
      <strong>useful</strong> solutions to <strong>real problems</strong> that
      I've created for my personal workflows, that have helped me (and others)
      work more effectively and efficiently. That's what gets me excited 😀
    </em>
  </p>
    </PageHeader>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/bowernite/highlight-the-web"
      }}>{`Highlight the Web`}</a>{` 🪄`}</h3>
    <p>{`Viewing web pages, absorbing their information, and then pointing others to that information, is more-or-less what a lot of us do every day -- especially when we're spending a lot of our day learning.`}</p>
    <p>{`So that's why I built an Alfred workflow that automates that process, so that you can `}<strong parentName="p">{`highlight web content`}</strong>{` as you read it, like you would a high school textbook. It allows users to `}<strong parentName="p">{`point to a web page`}</strong>{` (or `}<strong parentName="p">{`even specific text`}</strong>{` in that web page!) in a markdown link that is `}<strong parentName="p">{`intelligently and automatically named`}</strong>{` and formatted.`}</p>
    <p>{`It's particularly handy when taking notes for `}<a parentName="p" {...{
        "href": "https://github.com/bowernite/wiki"
      }}>{`my wiki`}</a>{`. A good example of that is the `}<a parentName="p" {...{
        "href": "https://github.com/bowernite/wiki"
      }}>{`page here`}</a>{` I made when researching Vitamin D; it was a breeze to learn and collect that information, and I can now refer to it when I inevitably forget it.`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/bowernite/browser-utils"
      }}>{`Browser Utils`}</a>{` 🐧`}</h3>
    <p>{`Sweet name, right?`}</p>
    <p>{`Eh, whatever 😅`}</p>
    <p>{`This is a browser extension that lets me `}<strong parentName="p">{`dive into the browser`}</strong>{`, and `}<strong parentName="p">{`tweak it to my liking`}</strong>{` when I need to. For example, it overrides `}<inlineCode parentName="p">{`console.dir`}</inlineCode>{` to actually be a much more useful `}<strong parentName="p">{`logger function`}</strong>{` that color codes, formats, and distinct-ifies my logs for much `}<strong parentName="p">{`easier debugging`}</strong>{`.`}</p>
    <p>{`Check out the repo for other uses.`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/bowernite/dotfiles"
      }}>{`Dotfiles`}</a>{` 👨‍💻`}</h3>
    <p>{`My dotfiles is my sanctuary of my `}<strong parentName="p">{`computer's configuration and automation`}</strong>{`. It's what lets me tune a tool that I use umpteen hours a week to my liking, and confidently be able to pick up its configuration on any other machine within minutes.`}</p>
    <p>{`While I don't think how well you configure your computer or how efficiently you can pound out macros is the "meat" of how effective you can be at your job (or otherwise), I do believe that `}<strong parentName="p">{`cutting out the "fat"`}</strong>{` (i.e. the annoying or automatable or energy-consuming tasks) of that work can give you the marginal `}<strong parentName="p">{`time and energy`}</strong>{` to do a little (or even a lot) `}<strong parentName="p">{`more every day`}</strong>{`. And I love how that becomes more and more `}<strong parentName="p">{`exponential over time`}</strong>{`.`}</p>
    <p>{`And that's what my dotfiles help me do 🪄`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/bowernite/base-project-config"
      }}>{`Base Project Config`}</a>{` 🔧`}</h3>
    <p>{`Base `}<strong parentName="p">{`configuration`}</strong>{` for a `}<strong parentName="p">{`JavaScript project`}</strong>{`. So that I can keep track of what works for me in a centralized place.`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://thedoofi.com"
      }}>{`thedoofi.com`}</a>{` 🤘🏼`}</h3>
    <p>{`In 2019, I designed and developed a rudimentary website for the Milwaukee band The Doofi. Here's a preview:`}</p>
    <img src={DoofiImg} width="600" style={{
      "padding": "0 2rem"
    }} />
    <br />
    <p>{`I kind of cringe when I look at it now, but it was a ton of fun to build and helped me develop my creative side and design skills. There's definitely some things I would do differently if I had to start over again (the design is... 😅), and things I would add if they wanted me to do any additional work.`}</p>
    <p>{`Plus, they're an awesome band, so I was happy to help them out 😄`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      