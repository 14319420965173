import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/Layout.jsx";
import { PageHeader } from "../components/PageHeader";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader title="Skills" mdxType="PageHeader">
  <p>
    Here's a basic summary of the tech I've worked with, organized by my current
    level of confidence with them.
  </p>
  <blockquote>
    Be sure to check out <a href="/toolkit/">Toolkit.md</a> for a more more
    detailed look at my favorite tech to work with 🧰
  </blockquote>
    </PageHeader>
    <h2>{`✅ Well-versed`}</h2>
    <ul>
      <li parentName="ul">{`React`}</li>
      <li parentName="ul">{`React Hooks`}</li>
      <li parentName="ul">{`JavaScript (ES 2020)`}</li>
      <li parentName="ul">{`TypeScript`}</li>
      <li parentName="ul">{`GraphQL`}</li>
      <li parentName="ul">{`Apollo`}</li>
      <li parentName="ul">{`SQL/MySQL`}</li>
      <li parentName="ul">{`CSS`}</li>
      <li parentName="ul">{`Accessible HTML`}</li>
      <li parentName="ul">{`Jest`}</li>
      <li parentName="ul">{`Testing Library`}</li>
      <li parentName="ul">{`CSS-in-JS solutions (CSS modules, Emotion)`}</li>
      <li parentName="ul">{`Redux`}</li>
    </ul>
    <h2>{`📈 Ramping up...`}</h2>
    <ul>
      <li parentName="ul">{`AWS`}</li>
      <li parentName="ul">{`Docker`}</li>
      <li parentName="ul">{`Kubernetes`}</li>
      <li parentName="ul">{`Svelte`}</li>
      <li parentName="ul">{`Vue`}</li>
      <li parentName="ul">{`Terraform`}</li>
      <li parentName="ul">{`Shell scripting`}</li>
    </ul>
    <h2>{`🧹 Heavily experienced, but a bit rusty`}</h2>
    <ul>
      <li parentName="ul">{`MongoDB`}</li>
      <li parentName="ul">{`AngularJS`}</li>
      <li parentName="ul">{`MongooseJS`}</li>
      <li parentName="ul">{`Java`}</li>
    </ul>
    <h2>{`⌛ Yeah... it's been a minute`}</h2>
    <ul>
      <li parentName="ul">{`C`}</li>
      <li parentName="ul">{`C++`}</li>
      <li parentName="ul">{`Python`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      