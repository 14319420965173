import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/Layout.jsx";
import { PageHeader } from "../components/PageHeader";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader title="AkitaBox" startDate="2017" endDate="2019" positions={["Junior Software Engineer", "Software Engineer", "Software Engineer Team Lead"]} imgSrc="/akitabox.png" squareImg mdxType="PageHeader" />
    <p><a parentName="p" {...{
        "href": "https://home.akitabox.com/"
      }}>{`AkitaBox`}</a>{` was my first job out of school. It was an absolute `}<strong parentName="p">{`dream job`}</strong>{`, and had a huge role in shaping who I am as an engineer and teammate.`}</p>
    <p>{`It was a startup, and still in the early phases when I joined (~30 employees). But by the time I left after 2 years, that was more like 90 employees. It was super interesting to not only witness the different phases of growth of a startup, but also participate in shaping what they looked like.`}</p>
    <br />
    <hr />
    <br />
    <p>{`This job was huge for my personal growth, and I'm incredibly grateful for the opportunities afforded to me there.`}</p>
    <p>{`I started off as a Junior SE that was willing to just work like crazy and stay until 2am for deployments and hotfixes. After 6 months or so, I was promoted to SE and earned the opportunity to have a voice in more impactful conversations (participation in design review, technical direction, customer relationships).`}</p>
    <p>{`After a year in that role, I was promoted to `}<strong parentName="p">{`lead`}</strong>{` of my agile team. We never really quite had one to that point, so there was an opportunity there. I had been informally filling the role for months out of necessity (grooming tickets, leading meetings, collaboration with the product team), so my manager decided to make it official. He called this "volunteer leadership" at the time, and I've tried to take that attitude into my job ever since.`}</p>
    <h3>{`On and on...`}</h3>
    <p>{`I could keep going about how it was to manage people with more experience than me, the life and pressures of working at a startup, the relationships I had; but I would probably go on way too long for what this website is set up for. In short, AkitaBox will always hold a special place in my heart.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      