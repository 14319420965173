import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/Layout.jsx";
import { PageHeader } from "../components/PageHeader";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader title="" startDate="2022" endDate="Present" positions={["Senior Full Stack Engineer"]} imgSrc="/gather.png" squareImg mdxType="PageHeader" />
    <p>{`When I moved back from Berlin to the US, I had to pivot and find a job here. And if you couldn't tell from my last position at `}<a parentName="p" {...{
        "href": "/wonder/"
      }}>{`Wonder`}</a>{`, I kind of have a thing for spatial gathering apps. Specifically, trying to make remote work just as fun and engaging as being in an office.`}</p>
    <p>{`So it only makes sense that I ended up at `}<a parentName="p" {...{
        "href": "https://www.gather.town/"
      }}>{`Gather`}</a>{`, where that's exactly what we're trying to do. Our motto these days is "never miss the office again". Wouldn't that be sweet?`}</p>
    <p>{`Anyways, it's a ton of fun and a super great technical challenge. (oh, and, `}<a parentName="p" {...{
        "href": "https://www.gather.town/careers"
      }}>{`we're hiring`}</a>{` 🙂)`}</p>
    <img src={require("../assets/gather-demo.png")} alt="A demo of Gather, where coworkers are walking around desks and rooms to collaborate with each other" style={{
      marginTop: 32
    }} />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      