import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/Layout.jsx";
import { PageHeader } from "../components/PageHeader";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader title="👨‍💻 The Ten-Factor Engineer" mdxType="PageHeader">
  <p>
    <em>
      This section represents 10 factors that I think are the most important to
      being a good software engineer and teammate. Inspired by{" "}
      <a href="//12factor.net">The Twelve-Factor App</a>.
    </em>
  </p>
    </PageHeader>
    <h2>{`I. Product-oriented`}</h2>
    <p>{`Every decision or disagreement -- which tech to use, what to prioritize, which agile framework to follow, etc. -- should be focused on answering one question: which is going to build the best product the fastest?`}</p>
    <h2>{`II. Pragmatism`}</h2>
    <p>{`No situation, product, bug, or context in this industry is exactly alike. Consider each problem independently, and come up with the best solution for `}<strong parentName="p">{`that`}</strong>{` problem.`}</p>
    <h2>{`III. Paranoia`}</h2>
    <p>{`Good software teams are paranoid and constantly seek out what could go wrong. They find the problems before the problems come to them.`}</p>
    <h2>{`IV. Dev Tools`}</h2>
    <p>{`The more paranoia you can shift onto automated tools, the better. Rely on computers to do what they do best (automate) to catch bugs, so you can focus on features.`}</p>
    <h2>{`V. Relationships`}</h2>
    <p>{`Highly performant teams value team culture and relationships over being "right". Their members don't let problems fester, and as a result, they enjoy working with and for one another.`}</p>
    <h2>{`VI. Feedback`}</h2>
    <p>{`The best engineers actively seek out constructive feedback and look to give it to their peers whenever possible.`}</p>
    <h2>{`VII. Learning`}</h2>
    <p>{`Learning never stops. Individually, an engineer should never stop learning new tools, concepts, and mental models. Collectively, the team should never stop learning about their customers and the team itself.`}</p>
    <h2>{`VIII. Genuine interest`}</h2>
    <p>{`A team will likely never reach its true peak unless all of its members genuinely want the end product to succeed.`}</p>
    <h2>{`IX. Team-oriented`}</h2>
    <p>{`Good teammates perform well individually; great ones lift the whole team up.`}</p>
    <h2>{`X. Perspective`}</h2>
    <p>{`Very rarely are software teams building life-or-death apps. They shouldn't take things too seriously; build something great together and have fun doing it 🙂`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      