import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/Layout.jsx";
import { PageHeader } from "../components/PageHeader";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader title="" startDate="2021" endDate="2022" positions={["Senior Full Stack Engineer"]} imgSrc="/wonder.png" squareImg mdxType="PageHeader">
  <p>
    <em>
      In the spring of 2021, my wife and I moved to Berlin, Germany -- and
      that's how I ended up at Wonder.
    </em>
  </p>
    </PageHeader>
    <p>{`You know how Zoom is like... ok, but something is still "off" when it comes to it supposedly bringing people closer together remotely?`}</p>
    <p>{`That's exactly why I joined `}<a parentName="p" {...{
        "href": "https://wonder.me"
      }}>{`Wonder`}</a>{` (now defunct 😕) -- to redefine how the world meets virtually, in a way that is fluid and leaves you feeling `}<em parentName="p">{`energized`}</em>{`, instead of fatigued.`}</p>
    <p>{`Technically, rendering hundreds of users on a canvas with real time updates while also encoding, decoding, and sending & receiving video over WebRTC is a novel challenge in performance and scalability. It was a welcome and refreshing experience, and reminded me how fun it can be to be challenged when building web apps that aren't basic CRUD apps.`}</p>
    <p>{`But even more importantly (and primarily the reason I joined) it was a mind-boggling and creative `}<em parentName="p">{`product`}</em>{` endeavor. Think about it -- the value add is trying to recreate something that exists in the physical world, virtually. There's so many paths that could take! What parts of the physical world do we want to keep? Which parts to we want to get rid of? Or even better yet, `}<em parentName="p">{`improve`}</em>{` upon aspects of the physical world, `}<strong parentName="p">{`for the first time`}</strong>{`... ever..?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      