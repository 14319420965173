import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/Layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Oh boy... 🤦‍♂️`}</h2>
    <p>{`It's not you, it's me.`}</p>
    <p>{`Well... maybe it is you. Hard to tell.`}</p>
    <p>{`Regardless, I got nothing for ya here.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      