import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/Layout.jsx";
import { PageHeader } from "../components/PageHeader";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader title="Ancilla Partners" startDate="2016" endDate="2016" positions={["Web Developer Intern"]} imgSrc="/ancilla-partners.png" squareImg mdxType="PageHeader" />
    <p>{`In my last summer between semesters at UW-Madison, I was offered a position as Web Developer Intern at small company called Ancilla Partners. They manage apps and websites for healthcare organizations and their data.`}</p>
    <p>{`This job was pretty crucial for me. While I had no problem focusing and performing well in classes at school, I had exactly 0 experience in software outside of the theoretical world of academia.`}</p>
    <p>{`Because of this, I was learning sooooo much every single day at Ancilla. I learned what git was. Learned CSS and JavaScript and PHP and SQL. Learned what a "standup" was. And most importantly, started to learn how to learn on the job.`}</p>
    <p>{`Admittedly, this job was pretty different than my others in hindsight. These days, I'd say I'm working with in a much more "modern" tech stack and exposed to more parts of the web dev stack as a whole. Regardless, I couldn't have gotten to where I am today if I wasn't given the opportunity to dip my toes in the water in 2016 🙂`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      